<template>
  <div
    :class="{
      'F2gRoundedTitle no-edition': edit,
      'F2gRoundedTitle edition': !edit,
    }"
    :style="
      edit
        ? `border-color: ${ownerPrimaryColor}; background-color: ${this.ownerPrimaryColor}`
        : `border-color: ${ownerPrimaryColor}; background-color: `
    "
    class="mb-4"
  >
    <div class="F2gRoundedTitle-icon" :style="setIconBackground()">
      <div :class="{ 'title-icon': isSvg }">
        <F2gIcon
          v-if="useCustomIcon"
          :stroke="customIconColor"
          :value="customIcon"
          :file="customIconFile"
          :width="22"
        ></F2gIcon>
        <F2gIcon
          v-else-if="edit && !useCustomIcon"
          :fill="setIconEditColor()"
          :file="setIconEditFile()"
          :key="1"
          solid
          :value="setEditIconValue()"
          :width="22"
        ></F2gIcon>
        <F2gIcon
          v-else-if="!edit && !useCustomIcon"
          :value="setViewIconValue()"
          :file="setIconViewFile()"
          :stroke="setIconViewColor()"
          :height="24"
          :width="24"
          :strokeWidth="4"
        />
      </div>
    </div>
    <div
      :title="title"
      class="my-auto text--lg"
      :style="
        edit ? `color: ${ownerOnPrimaryColor};` : `color: ${ownerPrimaryColor};`
      "
    >
      <v-layout>
        <span class="ml-2 mt-1 F2gRoundedTitle-text">
          {{ title }}
        </span>
        <span
          v-if="hasHelpTip && $PageUtils.canShowHelpTexts(isForm)"
          class="pt-2"
        >
          <F2gIcon
            class="svg mt-0 ml-2 help-icon"
            value="question-mark"
            :height="18"
            :width="18"
            :stroke="edit ? ownerOnPrimaryColor : ownerPrimaryColor"
            @click.stop="showHelpMessage = !showHelpMessage"
          />
        </span>
      </v-layout>
    </div>
    <transition name="fade">
      <F2gUxInfo
        v-if="showHelpMessage"
        :message="helpMessage"
        :isForm="isForm"
        :showHelpIcon="false"
        :showHelpMessageFromOutside="showHelpMessage"
        :helpCardTopPixels="20"
        :helpCardLeftPixels="-16"
        :helpCardWidth="300"
        @clickedAway="showHelpMessage = false"
      />
    </transition>
    <div v-if="hasActionSlot" class="actions">
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "F2gRoundedTitle",

  props: {
    editionIcon: String,
    viewIcon: String,
    edit: Boolean,
    isSvg: {
      type: Boolean,
      required: false,
      default: false,
    },
    editIconColor: String,
    viewIconColor: String,
    fileEditionIcon: String,
    fileViewIcon: String,
    useCustomIcon: { type: Boolean, default: false },
    customIcon: String,
    customIconColor: String,
    customIconFile: String,
    title: String,
    hasHelpTip: { type: Boolean, required: false, default: false },
    helpMessage: { type: String, required: false, default: "" },
    isForm: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    ownerOnPrimaryColor: null,
    ownerPrimaryColor: null,
    isIntranet: null,
    iconfill: null,
    showHelpMessage: false,
  }),
  computed: {
    ...mapGetters({
      userProfile: "auth/getUserProfile",
      ownerColors: "auth/getOwnerColors",
    }),
    hasActionSlot() {
      return !!this.$slots["actions"];
    },
  },
  mounted() {
    this.ownerOnPrimaryColor = this.ownerColors.ownerOnPrimaryColor;
    this.ownerPrimaryColor = this.ownerColors.ownerPrimaryColor;
  },
  methods: {
    setIconBackground() {
      if (process.env.VUE_APP_TITLE == "Intranet") {
        return `background-color: #42a549;  border-color: #42a549;`;
      } else if (this.ownerColors.personalizationContent == false) {
        return `background-color: #42a549;  border-color: #42a549;`;
      } else if (this.edit) {
        return `background-color: ${this.ownerOnPrimaryColor}; border-color: ${this.ownerOnPrimaryColor};`;
      } else {
        return `background-color: ${this.ownerPrimaryColor}; border-color: ${this.ownerPrimaryColor};`;
      }
    },
    setIconEditColor() {
      if (process.env.VUE_APP_TITLE == "Intranet") {
        return "#ffffff";
      } else if (this.ownerColors.personalizationContent == false) {
        return "#ffffff";
      } else if (this.editIconColor) {
        return this.editIconColor;
      } else {
        return this.ownerPrimaryColor;
      }
    },
    setIconViewColor() {
      if (process.env.VUE_APP_TITLE == "Intranet") {
        return "#ffffff";
      } else if (this.ownerColors.personalizationContent == false) {
        return "#ffffff";
      } else if (this.viewIconColor) {
        return this.viewIconColor;
      } else {
        return this.ownerOnPrimaryColor;
      }
    },

    setIconEditFile() {
      if (this.fileEditionIcon) {
        return this.fileEditionIcon;
      } else {
        return "icons_solid.svg";
      }
    },

    setIconViewFile() {
      if (this.fileViewIcon) {
        return this.fileViewIcon;
      } else {
        return "icons_lined.svg";
      }
    },

    setEditIconValue() {
      if (this.editionIcon) {
        return this.editionIcon;
      } else if (this.edit) {
        return "edit";
      } else {
        return "view";
      }
    },
    setViewIconValue() {
      if (this.viewIcon) {
        return this.viewIcon;
      } else if (this.edit) {
        return "edit";
      } else {
        return "view";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.F2gRoundedTitle {
  display: flex;
  flex-direction: row;
  height: 40px;
  width: 100%;
  border: 1px solid;
  background: linear-gradient(to right, 50.1%, transparent 50%);
  background-size: 200% 100%;
  transition: all 1s ease-out;
  border-radius: 20px !important;

  .actions {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: fit-content;
    padding: 0px 15px;
    margin-left: auto;
  }
  .F2gRoundedTitle-text {
    display: block;
    margin-top: 1.33em;
    margin-bottom: 1.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  .F2gRoundedTitle-icon {
    display: flex;
    justify-content: flex;
    align-items: center;
    float: left;
    width: 40px;
    border: 1px solid;
    margin-top: -1px;
    margin-left: -1px;
    background-color: #42a549;
    border-radius: 20px;
    height: 40px;
    color: #ffffff;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

    .title-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
    .title-icon svg {
      height: 20px;
      width: 20px;
    }
  }

  h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 65%;
    margin-left: 10px;
  }

  @media (max-width: 400px) {
    h3 {
      margin-left: 2px;
      font-size: 0.8rem;
    }
  }
}

.F2gRoundedTitle.edition {
  background-position: left bottom;
  h3 {
    color: white;
  }
  .help-icon {
    margin-top: 12px;
    cursor: pointer;
  }
}

.F2gRoundedTitle.no-edition {
  background-position: right bottom;
  h3 {
    color: #1c8523;
  }
  .help-icon {
    margin-top: 12px;
    cursor: pointer;
  }
}
</style>
